import React, { useCallback, useContext } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import RegistryService from 'services/api/RegistryService';
import { defaultFormProps } from 'util/Form';
import { ActionButtons, CardNumberInput } from './components';
import { ReportContext } from '../ReportsPage/context';

type Values = typeof defaultValues;
const defaultValues = {
  nccerNumber: '',
};

export function AssessmentScoreReportForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { closeModal, setLoading } = useContext(ReportContext);
  const form = useForm<Values>({
    ...defaultFormProps,
    defaultValues,
  });
  const { formState, handleSubmit } = form;

  const submitDisabled = !formState.isValid || !formState.isDirty;

  const handleFormSubmit = useCallback(
    async (data: Values) => {
      setLoading(true);
      try {
        await RegistryService.getScoreReportIndividual(data.nccerNumber);
        enqueueSnackbar(`Downloading file`, { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error, { variant: 'error' });
      } finally {
        closeModal();
        setLoading(false);
      }
    },
    [closeModal, enqueueSnackbar, setLoading]
  );

  return (
    <FormProvider {...form}>
      <Grid container spacing={4} pb={6}>
        <CardNumberInput name="nccerNumber" label="NCCER Number" />
      </Grid>
      <ActionButtons
        submitText="Generate Report"
        disabled={submitDisabled}
        onClose={closeModal}
        onSubmit={handleSubmit(handleFormSubmit)}
      />
    </FormProvider>
  );
}
